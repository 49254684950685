@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 1.5em;
  font-weight: bold;
}

.form-group {
  margin-bottom: 1rem;
  align-items: baseline;
  max-width: 30em;
}

.form-group .form-label {
  display: block;
}

@media (min-width: 480px) {
  .form-group {
    display: flex;
  }
  
  .form-group .form-label {
    flex: 0 0 8rem;
    text-align: right;
    padding-right: 1rem;
  }
}

input#yob {
  width: 6em;
}

